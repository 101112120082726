import React, {useEffect, useState} from "react"
import {Link} from "gatsby";

const cookieAcceptKey = "legal-software-accept-cookie-policy-001"

const AcceptCookiePolicy = () => {

    const [hasAccepted, setHasAccepted] = useState(true)

    const onAccept = () => {
        setHasAccepted(true)
        window.localStorage.setItem(cookieAcceptKey, "1")
    }

    useEffect(() => {
        const item = window.localStorage.getItem(cookieAcceptKey)
        if(!item){
            setHasAccepted(false)
        }
    })

    if(hasAccepted) return null

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 100,
                padding: "2rem",
                backgroundColor: "rgba(0,0,0,0.9)",
                color: "rgba(255,255,255,0.9)"
            }}>
            <div className="align-items-center">
                <h5 className="bold">Cookie-Richtlinie</h5>
                <p className="light">
                    Wir verwenden Cookies ausschließlich zu dem Zweck, die Zugriffe auf unsere Webseite zu analysieren.
                    Mehr Informationen finden Sie in unserer
                    {' '}
                    <Link className="normal" to={"/datenschutz"}>Datenschutzerklärung</Link>.
                </p>
                <div style={{textAlign: "center", margin: "2rem 0 0"}}>
                    <button
                        style={{
                            backgroundColor: "rgba(255,255,255,0.9)",
                            color: "rgba(0,0,0,0.9)",
                            fontSize: "1rem"
                        }}
                        onClick={() => {
                        onAccept()
                    }}
                    >
                        Akzeptieren
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AcceptCookiePolicy