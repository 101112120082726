import React from "react"
import {Link} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBuilding, faEnvelope, faMapMarkerAlt, faMapPin, faPhone, faServer} from "@fortawesome/free-solid-svg-icons"
import Icon from "./presentation/Icon";
import AcceptCookiePolicy from "./AcceptCookiePolicy";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

function Footer(){
    return (
        <footer style={{
            width: "100%",
            padding: "2rem",
            color: "rgba(255,255,255,0.8)"
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <FooterLink to="/" className="bold">Legal Software UG</FooterLink>
                        <p className="light">
                            Wir entwickeln Plattformen und SaaS-Lösungen, um die Rechtsbranche zu transformieren.
                        </p>
                        <div className="prg" style={{
                            width: "40%",
                            marginBottom: "1rem",
                            borderBottom: "solid 1px rgba(255,255,255,0.7)"
                        }}/>
                        <FooterLink to="https://goo.gl/maps/ughnqjsVCReNu8Ad7" external>
                            <Icon icon={faMapMarkerAlt} left/> Feilitzschstr. 7, München
                        </FooterLink>
                        <FooterLink to="tel:+498922061333" external>
                            <Icon icon={faPhone} left/> +49 (0)89 2206 1333
                        </FooterLink>
                        <FooterLink to="mailto:info@legalsoftware.io" external>
                            <Icon icon={faEnvelope} left/> info@legalsoftware.io
                        </FooterLink>
                    </div>
                    <div className="col-sm-4">
                        <FooterLink to="/datenschutz">
                            <Icon icon={faServer} left/> Datenschutz
                        </FooterLink>
                        <FooterLink to="/impressum">
                            <Icon icon={faBuilding} left/> Impressum
                        </FooterLink>
                    </div>
                </div>
            </div>
        </footer>
    )
}

function FooterLink({to, children, external, className}){
    if(external){
        return (
            <div className={className} style={{ marginBottom: "0.75rem"}}>
                <a href={to} target="_blank">{children}</a>
            </div>
        )
    }

    return (
        <div className={className} style={{ marginBottom: "0.75rem"}}>
            <Link to={to}>{children}</Link>
        </div>
    )
}

function Layout({children}){
    return (
        <div>
            <AcceptCookiePolicy />
            <main>
                {children}
            </main>
            <Footer/>
        </div>)
}

export default Layout