import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Icon({icon, left, right, size}){

    return (
        <span style={{
            display: "inline-block",
            width: size === "lg" ? "1.5rem" : "1.0rem",
            marginLeft: right ? "0.3em" : 0,
            marginRight: left ? "0.3em" : 0,
        }}>
            <FontAwesomeIcon
                icon={icon}
                size={size}
                style={{
                    margin: "auto"
                }}
            />
        </span>)

}


export default Icon